import { SessionUpdate } from './models/session-update';
import { Injectable } from '@angular/core';
import { SessionState } from './models/session-state.model';
import { SessionService } from '../session.service';
import { CurrentUserService } from '../current-user.service';

@Injectable({
  providedIn: 'root'
})
export class SessionProvider {

  constructor(
    private sessionService: SessionService,
    private currentUserService: CurrentUserService
  ) {}

  getSession(): SessionState {
    return {
      parashift_powers: this.sessionService.parashift_powers
    };
  }

  updateSession(sessionUpdate: SessionUpdate): SessionUpdate {
    const keys = Object.keys(sessionUpdate);
    keys.forEach((key: string) => this.sessionService[key as keyof SessionUpdate] = sessionUpdate[key as keyof SessionUpdate]);
    keys.length > 0 && this.currentUserService.saveSettings();
    return sessionUpdate;
  }
}
