import { Injectable } from '@angular/core';
import { Store } from '@parashift/shared/utils';
import { SessionState } from './models/session-state.model';
import { SessionProvider } from './session-provider.service';
import { SessionUpdate } from './models/session-update';
import { updateSession } from './reducers/update-session.reducer';

const INITIAL_STATE: SessionState = {
  parashift_powers: false
};

@Injectable({
  providedIn: 'root'
})
export class SessionStore extends Store<SessionState> {

  constructor(private sessionProvider: SessionProvider) {
    super(INITIAL_STATE);
  }

  init() {
    this.update(() => this.sessionProvider.getSession());
  }

  updateSession(sessionUpdate: Partial<SessionUpdate>) {
    this.update(updateSession(sessionUpdate));
    this.sessionProvider.updateSession(sessionUpdate);
  }
}
